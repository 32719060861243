import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import DateSelector from './components/DateSelector/DateSelector';
import OfficeMap from './components/OfficeMap';
import MyReservation from './components/MyReservation';
import AdminReservation from "./components/AdminReservation";
import {Col, Row} from 'react-bootstrap';
import {RootState} from "./store/store";
import {useSelector} from "react-redux";

import {useAuth} from "./services/hooks/useAuth";
import {useCalendar} from "./services/hooks/useCalendar";

const App = () => {
    const offices = useSelector((state: RootState) => state.office)

    const {user, signedIn, signOut, onSignedIn} = useAuth();
    const {date, updateDate, cancelReservation, addReservation, today} = useCalendar({offices, user, signedIn});

    return (
        <div className="App">
            <Header user={user?.name} signOut={signOut} onSignedIn={onSignedIn} signedIn={signedIn}/>
            {signedIn ?
                <Container>
                    <Row>
                        <DateSelector date={date} startDate={today} onChange={updateDate}/>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            {user.isAdmin &&
                                <AdminReservation offices={offices} addReservation={addReservation}/>
                            }
                            <MyReservation user={user} offices={offices} addReservation={addReservation}
                                           cancelReservation={cancelReservation} date={date}/>
                        </Col>
                        <Col sm={8}>
                            <OfficeMap offices={offices} date={date} addReservation={addReservation}/>
                        </Col>

                    </Row>
                </Container> : null}
        </div>
    );
}

export default App;
