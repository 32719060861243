import {useCallback, useEffect, useState} from "react";
import {
    AddReservationPayload,
    addReservationThunk,
    DeleteReservationPayload,
    deleteReservationThunk,
    FetchReservationPayload,
    fetchReservations,
    OfficeState
} from "../../store/officeSlice";
import {AppDispatch} from "../../store/store";
import {useDispatch} from "react-redux";
import {User} from "../../types";
import {formatDate} from "../utils/dateUtils";

interface CalendarProps {
    offices: OfficeState[],
    signedIn: boolean,
    user: User,
}

export function useCalendar({
                                offices,
                                signedIn,
                                user,
                            }: CalendarProps) {
    const today = formatDate(new Date());

    const [date, setDate] = useState<string>(today);
    const dispatch: AppDispatch = useDispatch()

    const checkFreeOffice = useCallback(async (office: OfficeState) => {
        if (date) {
            const action: FetchReservationPayload = {
                email: office.email,
                date: date
            }
            dispatch(fetchReservations(action));
        }
    }, [date, dispatch]);

    useEffect(() => {
        if (signedIn) {
            offices.forEach((office) => checkFreeOffice(office));
        }
    }, [signedIn, date, checkFreeOffice, offices]);

    const updateDate = (newDate: string) => {
        setDate(newDate);
    };

    const addReservation = (reservedOffices: OfficeState[]) => {
        const action: AddReservationPayload = {
            user: user,
            offices: reservedOffices.map(office => office.email),
            date: date
        }
        dispatch(addReservationThunk(action));
    }

    const cancelReservation = (office: OfficeState) => {
        const action: DeleteReservationPayload = {
            eventId: office.reservations[date]!.id,
            attendees: office.reservations[date]!.attendees,
            date: date
        }
        dispatch(deleteReservationThunk(action));
    }

    return {
        date,
        updateDate,
        addReservation,
        cancelReservation,
        today
    }

}
