import {useCallback, useContext, useEffect, useRef} from 'react';
import {OverridableTokenClientConfig, TokenClientConfig, TokenResponse} from '../types';
import {EnvironmentContext} from "../../environment/environmentContext";
interface ImplicitFlowOptions {
    onSuccess?: (
        tokenResponse: Omit<
            TokenResponse,
            'error' | 'error_description' | 'error_uri'
        >,
    ) => void;
    onError?: (
        errorResponse: Pick<
            TokenResponse,
            'error' | 'error_description' | 'error_uri'
        >,
    ) => void;
    scope: TokenClientConfig['scope'];
}

interface UseGoogleLogin {
    login: (overrideConfig?: OverridableTokenClientConfig) => void;
    logout: () => void;
}

interface ClientRef {
    requestAccessToken: (overridableClientConfig?: OverridableTokenClientConfig) => void;
}

export default function useGoogleLogin({
                                           scope,
                                           onSuccess,
                                           onError
                                       }: ImplicitFlowOptions): UseGoogleLogin {
    const {clientId} = useContext(EnvironmentContext)
    const clientRef = useRef<ClientRef>();

    const onSuccessRef = useRef(onSuccess);
    onSuccessRef.current = onSuccess;

    const onErrorRef = useRef(onError);
    onErrorRef.current = onError;

    useEffect(() => {

        clientRef.current = window?.google?.accounts.oauth2.initTokenClient({
            client_id: clientId,
            scope,
            callback: (response: TokenResponse) => {
                if (response.error) return onErrorRef.current?.(response);

                onSuccessRef.current?.(response);
            },
        });
    }, [clientId, scope]);

    const login = useCallback(
        (overrideConfig?: OverridableTokenClientConfig) =>
            clientRef.current?.requestAccessToken(overrideConfig),
        [],
    );

    const logout = () => window?.google?.accounts?.id?.disableAutoSelect();

    return {
        login,
        logout,
    };
}
