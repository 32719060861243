export type OfficeType = {
    name: string;
    email: string;
    coord: string;
    reservation?: ReservationType;
}

export type User = {
    name: string;
    email: string;
    imageUrl: string;
    isAdmin: boolean;
}

export type ReservationType = {
    id: string;
    organizer: string;
    resource: string;
    imageUrl: string;
    organizerName: string;
    attendees: string[];
}

export const defaultUser: User = {
    name: '',
    email: '',
    imageUrl: '',
    isAdmin: false
}

interface Attendee {
    email: string;
    displayName: string;
    organizer: boolean;
    self: boolean;
    responseStatus: string;
    resource: boolean;
}

export type UserAttendee = Omit<Attendee, 'resource'>

export type OfficeAttendee = Pick<Attendee, 'email' | 'responseStatus' | 'resource'>;

export type Attendees = [UserAttendee, ...OfficeAttendee[]];

interface SharedProperties {
    displayName: string;
    imageUrl: string;
}

interface ExtendedProperties {
    shared: SharedProperties;
}

interface CalendarDate {
    dateTime: string;
    timeZone: string;
}

export interface CalendarEvent {
    summary: string;
    attendees: Attendees;
    visibility: string;
    location: string;
    start: CalendarDate;
    end: CalendarDate;
    extendedProperties: ExtendedProperties;
}

interface FetchReservationsFulfilledPayload {
    items: {
        status: string;
        attendees: OfficeAttendee[];
        id: string;
        location: string;
        organizer: { email: string };
        extendedProperties: { shared: { displayName: string; imageUrl: string } };
    }[];
}

export interface FetchReservationsFulfilledAction {
    type: string;
    payload: FetchReservationsFulfilledPayload;
    meta: {
        arg: {
            email: string;
            date: string;
        };
    };
}


