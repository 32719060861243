import useGoogleLogin from "./useGoogleLogin";
import {useCallback, useState} from "react";
import {defaultUser, User} from "../../types";
import {initClient} from "../GoogleApiService";

const SCOPES = "https://www.googleapis.com/auth/calendar";
export function useAuth(){
    const [signedIn, setSignedIn] = useState(false);

    const [user, setUser] = useState<User>(defaultUser);

    const {login, logout} = useGoogleLogin({scope: SCOPES, onSuccess: () => setSignedIn(true)});

    const onSignedIn = useCallback(async (user: User) => {
        if (!user) {
            return;
        }
        setUser(user)
        initClient(() => login({prompt: "consent"}));
    }, [login]);

    const signOut = async () => {
        logout();
        setSignedIn(false);
        setUser(defaultUser);
    };

    return {
        signedIn,
        user,
        onSignedIn,
        signOut
    }



}
