import {CalendarEvent} from "../types";

const gapi = window.gapi;
const CALENDAR_API = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';

const initClient = (callback: () => void) => {
    gapi.load('client', () => {
        try {
            gapi.client.init({}).then(() => {
                gapi.client.load(CALENDAR_API);
                callback();
            }).catch(console.error);
        } catch (error) {
            console.error(error);
        }
    });
};

const getEvents = async (id: string, date: string) => {
    try {
        const startDate = new Date(date);
        const endDate = new Date(date);
        endDate.setHours(23, 59, 59);

        let resp = await gapi.client.calendar.events.list({
            'calendarId': id,
            "timeMin": startDate.toISOString(),
            "timeMax": endDate.toISOString(),
            "timeZone": startDate.toISOString()
        });

        return resp.result;
    } catch (error) {
        console.error(error);
    }
}

const publishEvent = async (event: CalendarEvent) => {
    try {
        return gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
        });
    } catch (error) {
        console.error(error);
    }
}

const deleteEvent = async (eventId: string) => {
    try {
        await (gapi.client.calendar.events.delete({
            'calendarId': 'primary',
            'eventId': eventId,
        }))
    } catch (error) {
        console.error(error);
    }
}

export {
    publishEvent,
    getEvents,
    deleteEvent,
    initClient
}
