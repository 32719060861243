import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {OfficeState} from "../store/officeSlice";

type Props = {
    offices: OfficeState[];
    addReservation: Function;
}

const AdminReservation = (props: Props) => {
    const {offices, addReservation} = props
    const choices = ["Poste 1", "Poste 3", "Poste 4"];

    const reserveOffices = (selectedOffices: string) => {
        const reservedOffices = offices.filter(office => office.name.includes(selectedOffices));
        addReservation(reservedOffices);
    }

    return (
        <>
            <div>
                <h3>Bureaux : </h3>
                {
                    choices.map(choice => {
                        return (
                            <Card key={choice}
                                  className="mb-2">
                                <Card.Body>
                                    <Card.Title>{choice}XX</Card.Title>

                                    <Button variant="primary" onClick={() => reserveOffices(choice)}>
                                        Réserver
                                    </Button>

                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </div>
        </>
    )
}

export default AdminReservation;