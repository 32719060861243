import React, {useContext, useEffect, useRef} from 'react';

import {GoogleCredentialResponse, GsiButtonConfiguration, IdConfiguration} from '../services/types';
import {User} from "../types";
import {EnvironmentContext} from "../environment/environmentContext";

const containerHeightMap = {large: 40, medium: 32, small: 20};

export type GoogleLoginProps = {
    onSuccess: (user: User) => void;
    onError?: () => void;
    useOneTap?: boolean;
} & Pick<IdConfiguration, 'auto_select'> &
    GsiButtonConfiguration;

export default function GoogleLogin({
                                        onSuccess,
                                        onError,
                                        useOneTap,
                                        type = 'standard',
                                        theme = 'outline',
                                        size = 'large',
                                        width,
                                        auto_select,
                                    }: GoogleLoginProps) {
    const btnContainerRef = useRef<HTMLDivElement>(null);
    const {clientId, admins} = useContext(EnvironmentContext);
    const onSuccessRef = useRef(onSuccess);
    onSuccessRef.current = onSuccess;

    const onErrorRef = useRef(onError);
    onErrorRef.current = onError;

    useEffect(() => {
        window?.google?.accounts?.id?.initialize({
            client_id: clientId,
            callback: (credentialResponse: GoogleCredentialResponse) => {
                if (!credentialResponse?.credential) {
                    return onErrorRef.current?.();
                }

                const {credential} = credentialResponse;
                const decipheredSerializedJwtBody = window.atob(credential.split('.')[1])
                const jwtBody = JSON.parse(decipheredSerializedJwtBody);

                onSuccessRef.current({
                    name: jwtBody.name,
                    email: jwtBody.email,
                    isAdmin: admins.includes(jwtBody.email),
                    imageUrl: jwtBody.picture,
                });
            },
            auto_select,
        });

        window?.google?.accounts?.id?.renderButton(btnContainerRef.current!, {
            type,
            theme,
            size,
            text: 'signin_with',
            shape: 'pill',
            logo_alignment: 'center',
            width
        });

        if (useOneTap)
            window?.google?.accounts?.id?.prompt();

        return () => {
            if (useOneTap) window?.google?.accounts?.id?.cancel();
        };
    }, [
        clientId,
        useOneTap,
        type,
        theme,
        size,
        auto_select,
        width,
        admins,
    ]);

    return (
        <div
            ref={btnContainerRef}
            style={{height: containerHeightMap[size]}}
        />
    );
}
