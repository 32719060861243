export const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
}

export const isMondayOrSunday = (date: Date) => {
    return date.getDay() === 1 || date.getDay() === 0;
}

export const isFridayOrSaturday = (date: Date) => {
    return date.getDay() === 5 || date.getDay() === 6;
}
