import React, {useState} from 'react';
import {Button, Card, Col, Container, Image, Modal, Row} from 'react-bootstrap';
import {OfficeState} from "../store/officeSlice";
import {User} from "../types";

type Props = {
    user: User;
    offices: OfficeState[];
    date: string;
    addReservation: Function;
    cancelReservation: Function;
}

const MyReservation = (props: Props) => {
    const {user, offices, addReservation, cancelReservation, date} = props
    const [showReserve, setShowReserve] = useState<Map<string, boolean>>(new Map());
    const [showCancelReservation, setShowCancelReservation] = useState<Map<string, boolean>>(new Map());

    const handleShowReserve = (office: OfficeState) => setShowReserve(map => new Map(map.set(office.email, true)));

    const handleCloseReserve = (office: OfficeState) => setShowReserve(map => new Map(map.set(office.email, false)));

    const handleShowDelete = (office: OfficeState) => setShowCancelReservation(map => new Map(map.set(office.email, true)));

    const handleCloseDelete = (office: OfficeState) => setShowCancelReservation(map => new Map(map.set(office.email, false)));

    const reserveOffice = (office: OfficeState) => {
        addReservation([office]);
        setShowReserve(map => new Map(map.set(office.email, false)));
    }

    const cancel = (office: OfficeState) => {
        cancelReservation(office);
        setShowCancelReservation(map => new Map(map.set(office.email, false)));
    }

    const getBackgroundColor = (office: OfficeState) => {
        if (office.reservations[date]) {
            if (office.reservations[date]?.organizer === user.email) {
                return "success";
            }
            return "danger";
        }
        return "light";
    }

    // TODO size en dur...
    return (
        <>
            <div style={{overflowY: "scroll", height: "733px"}}>
                <h3>Bureaux : </h3>
                {offices.map((office) => {
                    const reservation = office.reservations[date];
                    return (
                        <div key={office.email}>
                            <Card key={office.email}
                                  bg={getBackgroundColor(office)}
                                  text={getBackgroundColor(office) === 'light' ? 'dark' : 'white'}
                                  className="mb-2">
                                <Card.Body>
                                    <Card.Title>{office.name}</Card.Title>
                                    {reservation ?
                                        <>
                                            <Container className="mb-2">
                                                <Row>
                                                    <Col sm={8}>
                                                        <Card.Text>
                                                            Réservé par {decodeURIComponent(reservation.organizerName)}
                                                        </Card.Text>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <Image src={reservation.imageUrl} roundedCircle
                                                               style={{height: "50px"}}/>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            {(reservation.organizer === user.email || user.isAdmin) &&
                                                <Button onClick={() => handleShowDelete(office)}>Supprimer</Button>}
                                        </> :
                                        <>
                                            <Card.Text>
                                                Bureau disponible !
                                            </Card.Text>
                                            <Button variant="primary" onClick={() => handleShowReserve(office)}>
                                                Réserver
                                            </Button>

                                        </>}
                                </Card.Body>
                            </Card>
                            {!reservation &&
                                <Modal show={showReserve.get(office.email)} onHide={() => handleCloseReserve(office)}
                                       key={office.email + "_reserve"}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Réserver un bureau</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body><p>Voulez vous réserver le bureau {office.name} ?</p></Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => handleCloseReserve(office)}>
                                            Annuler
                                        </Button>
                                        <Button variant="primary" onClick={() => reserveOffice(office)}>
                                            Réserver
                                        </Button>
                                    </Modal.Footer>
                                </Modal>}
                            {reservation &&
                                <Modal show={showCancelReservation.get(office.email)}
                                       onHide={() => handleCloseDelete(office)}
                                       key={office.email + "_delete"}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Supprimer la réservation</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body><p>Voulez vous supprimer votre réservation du bureau {office.name} ?</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => handleCloseDelete(office)}>
                                            Annuler
                                        </Button>
                                        <Button variant="primary" onClick={() => cancel(office)}>
                                            Supprimer
                                        </Button>
                                    </Modal.Footer>
                                </Modal>}
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default MyReservation;
