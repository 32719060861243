import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../resources/img/logo.png";
import Image from "react-bootstrap/Image";
import GoogleLogin from "./GoogleLogin";
import {Button, Nav} from "react-bootstrap";
import {User} from "../types";

type Props = {
    user: string;
    signOut: any;
    signedIn: boolean;
    onSignedIn: (user: User) => void;
}

const Header = ({signOut, onSignedIn, user, signedIn}: Props) => {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="#home">
                    <Image src={logo} alt="Carbon logo" fluid={true} width={150}/>
                </Navbar.Brand>
                <Navbar.Brand href="#home">
                    Flex Office
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse className="me-auto justify-content-end">
                    {!signedIn ? <GoogleLogin onSuccess={onSignedIn} useOneTap auto_select></GoogleLogin>
                        :
                        <>
                            <Nav>
                                <Navbar.Text className="me-2">
                                    {user}
                                </Navbar.Text>
                                <Button onClick={signOut}>Se déconnecter</Button>
                            </Nav>
                        </>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
