import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    Attendees,
    CalendarEvent,
    FetchReservationsFulfilledAction,
    OfficeAttendee,
    ReservationType,
    User
} from "../types";
import {deleteEvent, getEvents, publishEvent} from "../services/GoogleApiService";
import {RootState} from "./store";
import {parseFullNameFromEmail} from "../services/utils/userUtils";

export interface OfficeState {
    name: string;
    email: string;
    coord: string;
    reservations: Partial<{
        [date: string]: ReservationType;
    }>
}

const initialState: OfficeState[] =
    [
        {
            name: "Poste 101",
            email: "c_1885bdnkg2alih6ajjmcn92gam31q@resource.calendar.google.com",
            coord: "650,565,699,585",
            reservations: {}
        },
        {
            name: "Poste 102",
            email: "c_188c65gj2oddohc0gv1401akg558g@resource.calendar.google.com",
            coord: "702,562,751,585",
            reservations: {}
        },
        {
            name: "Poste 103",
            email: "c_18830kb3m3a0ogcelrgs32g48u15e@resource.calendar.google.com",
            coord: "754,564,804,587",
            reservations: {}
        },
        {
            name: "Poste 104",
            email: "c_1880l1g1csprcje0litqo3jks9lf0@resource.calendar.google.com",
            coord: "753,589,801,608",
            reservations: {}
        },
        {
            name: "Poste 105",
            email: "c_188be46oareeii6igj1af7mlgcqg4@resource.calendar.google.com",
            coord: "700,588,751,609",
            reservations: {}
        },
        {
            name: "Poste 106",
            email: "c_1889gsvcf32m4hobl5b2q29l8udoq@resource.calendar.google.com",
            coord: "650,588,699,610",
            reservations: {}
        },
        {
            name: "Poste 303",
            email: "c_188fs0normmi6i3dhurui1as0kmfq@resource.calendar.google.com",
            coord: "138,166,194,191",
            reservations: {}
        },
        {
            name: "Poste 306",
            email: "c_1882djsoj0ueii6dibn7i06poeo6q@resource.calendar.google.com",
            coord: "138,196,191,222",
            reservations: {}
        },
        {
            name: "Poste 401",
            email: "c_18877c15068eig6gn9vc5mgg5nn4q@resource.calendar.google.com",
            coord: "600,315,648,345",
            reservations: {}
        },
        {
            name: "Poste 402",
            email: "c_1886rlvk86up2i04mhjg541oba70o@resource.calendar.google.com",
            coord: "648,345,690,375",
            reservations: {}
        },
        {
            name: "Poste 403",
            email: "c_1888ipoaota84g42hdl5ef3hascbm@resource.calendar.google.com",
            coord: "600,345,648,375",
            reservations: {}
        },
        {
            name: "Poste 404",
            email: "c_188dh026589h0hjgh524pclufttka@resource.calendar.google.com",
            coord: "648,315,690,345",
            reservations: {}
        }
    ];

export type FetchReservationPayload = {
    email: string,
    date: string
}

export type AddReservationPayload = {
    offices: string[],
    date: string,
    user: User
}

export type DeleteReservationPayload = {
    date: string,
    eventId: string
    attendees: string[]
}

export const fetchReservations = createAsyncThunk(
    'office/fetchReservations',
    async (action: FetchReservationPayload, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const existingReservations = state.office.find(office => office.reservations[action.date]);
        if (!existingReservations) {
            return await getEvents(action.email, action.date)
        }
        return {items: []};
    }
)

function buildAttendees(user: User, offices: string[]): Attendees {
    const resources: OfficeAttendee[] = offices.map(office => {
        return {
            email: office,
            resource: true,
            responseStatus: 'accepted'
        }
    })

    return [
        {
            "email": user.email,
            "displayName": user.name,
            "organizer": true,
            "self": true,
            "responseStatus": "accepted",
        }, ...resources];
}

export const addReservationThunk = createAsyncThunk(
    'office/addReservation',
    async (action: AddReservationPayload, thunkAPI) => {
        const {user, date, offices} = action;
        const startDate = new Date(date);
        startDate.setHours(0);
        const endDate = new Date(date);
        endDate.setHours(0);
        endDate.setDate(endDate.getDate() + 1);

        const event: CalendarEvent = {
            summary: "Télétravail " + user.name,
            attendees: buildAttendees(user, offices),
            visibility: "public",
            location: "131 Av. Charles de Gaulle Hall A, 92200 Neuilly-sur-Seine",
            start: {
                dateTime: startDate.toISOString(),
                timeZone: 'Europe/Paris'
            },
            end: {
                dateTime: endDate.toISOString(),
                timeZone: 'Europe/Paris'
            },
            extendedProperties: {
                shared: {
                    displayName: encodeURIComponent(user.name),
                    imageUrl: user.imageUrl
                }
            }
        }
        return await publishEvent(event);
    }
)

export const deleteReservationThunk = createAsyncThunk(
    'office/deleteReservation',
    async (action: DeleteReservationPayload, thunkAPI) => {
        await deleteEvent(action.eventId);
    }
)

export const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchReservations.fulfilled, (state, action) => {
            // Add user to the state array
            const reservations = (action as FetchReservationsFulfilledAction).payload.items
                .filter((item: any) => item.status !== "cancelled")
                .filter((item: any) => item.attendees.some((attendee: any) => attendee.resource === true && attendee.responseStatus === "accepted"));
            const isReserved = reservations.length !== 0;
            let reservation: ReservationType | undefined = undefined;
            if (isReserved) {
                reservation = {
                    id: reservations[0].id,
                    organizer: reservations[0].organizer.email,
                    organizerName: reservations[0].extendedProperties?.shared?.displayName ?? parseFullNameFromEmail(reservations[0].organizer.email),
                    resource: reservations[0].location,
                    imageUrl: reservations[0].extendedProperties?.shared?.imageUrl ?? "",
                    attendees: reservations[0].attendees.map((attendee: any) => attendee.email)
                }
            }
            state = state.map(office => {
                    if (office.email === action.meta.arg.email && reservation) {
                        office.reservations[action.meta.arg.date] = reservation;
                    }
                    return office;
                }
            )
        })
        builder.addCase(addReservationThunk.fulfilled, (state, action) => {

            const reservedOffice: ReservationType = {
                id: action.payload.result.id,
                organizer: action.payload.result.organizer.email,
                organizerName: action.payload.result.extendedProperties?.shared?.displayName ?? parseFullNameFromEmail(action.payload.result.organizer.email),
                resource: action.payload.result.attendees[1].displayName,
                imageUrl: action.payload.result.attendees[0].comment,
                attendees: action.payload.result.attendees.map((attendee: any) => attendee.email)
            }
            state = state.map(office => {
                    if (action.meta.arg.offices.includes(office.email)) {
                        office.reservations[action.meta.arg.date] = reservedOffice;
                    }
                    return office;
                }
            )
        })
        builder.addCase(deleteReservationThunk.fulfilled, (state, action) => {
            state = state.map(office => {
                    if (action.meta.arg.attendees.includes(office.email)) {
                        office.reservations[action.meta.arg.date] = undefined;
                    }
                    return office;
                }
            )
        })
    },
})

export default officeSlice.reducer
