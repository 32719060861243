import React from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import left from "../../resources/img/chevron-left.svg";
import right from "../../resources/img/chevron-right.svg";
import {formatDate, isFridayOrSaturday, isMondayOrSunday} from "../../services/utils/dateUtils";

type Props = {
    onChange: Function
    startDate: string;
    date: string;
    //TODO sortir la validation de la date dans une props
}

const DateSelector = (props: Props) => {
    const {onChange, startDate, date} = props;

    const handleChange = (newDate: string) => {
        onChange(newDate);
    }

    const leftButton = () => {
        if (date === startDate) {
            return;
        }
        let newDate = new Date(date);
        let index = isMondayOrSunday(newDate) ? 2 + newDate.getDay() : 1;
        newDate.setDate(newDate.getDate() - index);
        onChange(formatDate(newDate));
    }

    const rightButton = () => {
        let newDate = new Date(date);
        /*let index = 1;
        if (newDate.getDay() === 5) {
            index = 3;
        }
        if (newDate.getDay() === 6) {
            index = 2;
        }*/
        //const array = [5,6];
        let index = isFridayOrSaturday(newDate) ? 8 - newDate.getDay() : 1;
        newDate.setDate(newDate.getDate() + index);
        onChange(formatDate(newDate));
    }

    return (
        <Container className='calenderEvent-wrapper m-3'>
            <Form>
                <Form.Group className='eventItem'>
                    <Row className="justify-content-md-center">
                        <Col xs="auto">
                            <img src={left} alt="left" width="32" height="32" onClick={leftButton}
                                 style={{cursor: "pointer"}}/>
                        </Col>
                        <Col xs="auto">
                            <Form.Control title="dateSelector" type="date" value={date} min={startDate}
                                          onChange={e => handleChange(e.target.value)}/>
                        </Col>
                        <Col xs="auto">
                            <img src={right} alt="right" width="32" height="32" onClick={rightButton}
                                 style={{cursor: "pointer"}}/>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Container>
    )
}

export default DateSelector;
