import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {OfficeState} from "../store/officeSlice";

type Props = {
    office: OfficeState;
    date: string;
    addReservation: Function;
}

const Office = (props: Props) => {
    const {office, addReservation, date} = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {
        e.preventDefault()
        setShow(true);
    }

    const submit = (e: any) => {
        e.preventDefault();
        addReservation([office]);
        setShow(false);
    }

    return (
        <div>
            <>
                <area target="" alt={office.name} title={office.name} href="" coords={office.coord} shape="rect"
                      onClick={handleShow}/>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Réserver un bureau</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{office.reservations[date] && office.reservations[date]?.organizer ?
                        <p>Bureau {office.name} réservé par {decodeURIComponent(office.reservations[date]?.organizerName!)}</p> :
                        <p>Voulez vous réserver le bureau {office.name} ?</p>}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fermer
                        </Button>
                        {!office.reservations[date] &&
                            <Button variant="primary" onClick={submit}>
                                Réserver
                            </Button>}
                    </Modal.Footer>
                </Modal>
            </>
        </div>
    )
}

export default Office;
