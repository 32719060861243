import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store/store";
import {EnvironmentContext} from "./environment/environmentContext";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
const admins = ["kevin.lorant@carbon-it.com", "maxime.mader@carbon-it.com", "vincent.vieira@carbon-it.com", "samuel.bader@carbon-it.com"];

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <EnvironmentContext.Provider value={{clientId, admins}}>
            <Provider store={store}>
                <App/>
            </Provider>
        </EnvironmentContext.Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
