import React from 'react';
import map from "../resources/img/carbon_compress.png";
import Office from './Office';
import {OfficeState} from "../store/officeSlice";
import {Image} from "react-bootstrap";

type Props = {
    offices: OfficeState[];
    date: string;
    addReservation: Function;
}

const OfficeMap = (props: Props) => {
    const {offices, addReservation, date} = props

    return (
        <>
            {/*<picture>
                <source srcSet={map_webP} type="image/webp"/>
                <source srcSet={map_avif} type="image/avif"/>
                <img src={map} alt={"Plan de Carbon"} width={860}/>
            </picture>

            <picture>
                <img
                    srcSet={`${map_200} 200w, ${map_926} 926w, ${map_1400} 1400w`}
                    src={map}
                    alt="Plan Carbon" width={860}/>
            </picture>*/}

            <Image src={map} useMap="#workmap" alt="Plan Carbon" width={860} fluid={true}/>
            <map id="workmap" name="workmap">
                {offices.map((office, index) => {
                    return (
                        <Office office={office} date={date} key={index} addReservation={addReservation}/>
                    )
                })}
            </map>
        </>
    )
}

export default OfficeMap;
